<template>
  <div class="auth-wrapper auth-v2">
    <b-link
      class="brand-logo d-flex justify-content-center align-items-center"
      :to="{ name: 'LoginMh' }"
    >
      <img
        :src="require('@/assets/images/logo/cne.svg')"
        width="50"
        height="50"
      />
      <h2 class="brand-text text-primary m-0 ml-1">Military Hospitals</h2>
    </b-link>

    <b-row class="auth-inner m-0 overlay">
      <b-col
        lg="6"
        class=""
        style="display: flex; align-items: center; justify-content: center"
      >
        <div class="text-center position-fixed d-md-block d-none">
          <img
            :src="require('@/assets/images/logo/cne.svg')"
            alt="AFP Logo"
            class="img-fluid"
            style="height: 300px; width: 300px"
          />
          <br />
          <br />
          <p
            class="text-primary"
            style="font-size: 18px; font-weight: bolder"
          ></p>
        </div>
      </b-col>

      <b-col lg="6" class="my-auto px-2 p-lg-5">
        <b-col sm="10" md="8" lg="9" class="px-xl-2">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to
            <span class="font-weight-bold"> Military Hospitals</span>
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account
          </b-card-text>

          <validation-observer ref="loginFormValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group>
                <label for="cnic" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <div class="d-inline-block">
                      <span>CNIC </span><span class="text-danger"> *</span>
                    </div>
                    <span
                      class="ml-2 d-inline-block"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        text-align: right !important;
                      "
                      >شناختی کارڈ
                    </span>
                  </div>
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="CNIC"
                  rules="required|integer|length:13"
                >
                  <b-form-input
                    id="cnic"
                    v-model="cnic"
                    :state="errors.length > 0 ? false : null"
                    name="cnic"
                    placeholder="61101XXXXXXXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="password" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <div class="d-inline-block">
                      <span>Password </span><span class="text-danger"> *</span>
                    </div>
                    <span
                      class="ml-2 d-inline-block"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        text-align: right !important;
                      "
                      >پاس ورڈ
                    </span>
                  </div>
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                id="signin"
                @click="validateForm"
              >
                Sign In
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Don't have an account? </span>
            <b-link :to="{ name: 'Register' }">
              <span>&nbsp;Click here</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    </b-row>
    <!-- <OtpModal
      @modalClosed="modalClosed"
      :cnic="cnic"
      :password="password"
      :key="`create-${OtpModalCount}`"
    /> -->
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
// import OtpModal from "@/components/auth/OtpModal.vue";
import { $themeConfig } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import store from "@/store";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // OtpModal,
  },
  mixins: [togglePasswordVisibility, util],
  data() {
    return {
      show: false,
      appName: "",
      appLogoImage: "",
      password: "",
      // OtpModalCount: 0,
      cnic: "",
      required,
    };
  },
  created() {
    store.commit("appConfig/UPDATE_SKIN", "feedback");
    this.appName = $themeConfig.app.appName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === "/home") {
      store.commit("appConfig/UPDATE_SKIN", "light");
    }
    next();
  },
  methods: {
    ...mapActions({
      login: "appData/login",
      // sendOtp: "appData/sendOtp",
      logout: "appData/logoutUnAuth",
    }),

    async modalClosed() {
      // document.getElementById("signin").disabled = false;
      // this.$bvModal.hide("otp-modal");
    },
    // openModal() {
    //   document.getElementById("signin").disabled = false;
    //   this.OtpModalCount += 1;
    //   this.$nextTick(() => {
    //     this.$bvModal.show("otp-modal");
    //   });
    // },
    async validateForm() {
      // document.getElementById("signin").disabled = true;
      const success = await this.$refs.loginFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      this.show = true;
      try {
        const res = await this.login({
          cnic: this.cnic,
          password: this.password,
        });
        if (res.status === 200 && res.data.user.role_data.name === "feedback" || res.data.user.role_data.name === "feedbackAdmin") {
          store.commit("appConfig/UPDATE_SKIN", "feedback");
          this.$router.push({ name: "FeedbackLanding" });
        } else {
          await this.logout();
          this.displayCustomError("Invalid Login");
        }
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      getUser: "appData/getUser",
      hasPermisson: "appData/hasPermission",
      hasRole: "appData/hasRole",
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.overlay {
  background: url("~@/assets/images/slider/login-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: absolute;
  background-blend-mode: multiply;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.urdu-text {
  direction: ltr;
  font-family: "Noto Nastaliq Urdu", serif;
}
</style>
